/*
@font-face {
    font-family: 'Poppins'; 
    src: url('../fonts/Poppins.woff2');
  }
*/
body {
    height: 100vh;
    padding: 0 !important;
    margin: 0 !important;

    /*background-image: url(../images/pattern.png);*/
    /*background-color: #101010 !important;*/

    /*font-family: 'Poppins', sans-serif !important;*/

    overscroll-behavior: contain;
}

#root {
    height: 100%;
}

.form-label {
    margin-bottom: .25em;
    font-weight: bold;
}

.label-required::after {
    content: " *";
    color: red;
}